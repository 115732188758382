// src/App.js

import React from 'react';
import './tailwind.css';

const App = () => {
  return (
    <div className="bg-slate-900 leading-relaxed text-slate-400 antialiased selection:bg-teal-300 selection:text-teal-900">
      <div className='mx-auto min-h-screen max-w-screen-xl px-6 py-12 font-sans md:px-12 md:py-20 lg:px-24 lg:py-0'>
        <div className='lg:flex lg:justify-between lg:gap-4'>
          {/* Encabezado */}
          <header className="lg:sticky lg:top-0 lg:flex lg:max-h-screen lg:w-1/2 lg:flex-col lg:justify-between lg:py-24">
            <div>
              <h1 class="text-4xl font-bold tracking-tight text-slate-200 sm:text-5xl">
                <a href="/">Israel Pereira</a>
              </h1>
              <h2 class="mt-3 text-lg font-medium tracking-tight text-slate-200 sm:text-xl">Fullstack developer</h2>
              <p class="mt-4 max-w-xs leading-normal first-letter-large">4 years of experience in developing robust and scalable web applications using the popular frameworks Laravel and Angular. With a strong knowledge in creating API REST and optimizing SQL databases.</p>
              <nav class="nav hidden lg:block" aria-label="In-page jump links">
                <ul class="mt-16 w-max">
                  <li>
                    <a class="group flex items-center py-3 active" href="#about">
                      <span class="nav-indicator mr-4 h-px w-8 bg-slate-600 transition-all group-hover:w-16 group-hover:bg-slate-200 group-focus-visible:w-16 group-focus-visible:bg-slate-200 motion-reduce:transition-none"></span>
                      <span class="nav-text text-xs font-bold uppercase tracking-widest text-slate-500 group-hover:text-slate-200 group-focus-visible:text-slate-200">About</span>
                    </a>
                  </li>
                  <li>
                    <a class="group flex items-center py-3" href="#experience">
                      <span class="nav-indicator mr-4 h-px w-8 bg-slate-600 transition-all group-hover:w-16 group-hover:bg-slate-200 group-focus-visible:w-16 group-focus-visible:bg-slate-200 motion-reduce:transition-none">
                      </span>
                      <span class="nav-text text-xs font-bold uppercase tracking-widest text-slate-500 group-hover:text-slate-200 group-focus-visible:text-slate-200">Experience</span>
                    </a>
                  </li>
                  <li>
                    <a class="group flex items-center py-3" href="#projects">
                      <span class="nav-indicator mr-4 h-px w-8 bg-slate-600 transition-all group-hover:w-16 group-hover:bg-slate-200 group-focus-visible:w-16 group-focus-visible:bg-slate-200 motion-reduce:transition-none">
                      </span>
                      <span class="nav-text text-xs font-bold uppercase tracking-widest text-slate-500 group-hover:text-slate-200 group-focus-visible:text-slate-200">Projects
                      </span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>

            {/* links socials */}
            <ul class="ml-1 mt-8 flex items-center" aria-label="Social media">
              <li class="mr-5 text-xs">
                <a class="block hover:text-slate-200" href="https://github.com/franper" target="_blank" rel="noreferrer">
                  <span class="sr-only">GitHub</span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="h-6 w-6" aria-hidden="true"><path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z"></path></svg>
                </a>
              </li>
              <li class="mr-5 text-xs">
                <a class="block hover:text-slate-200" href="https://www.linkedin.com/in/desarrollador-web-i-pereira/" target="_blank" rel="noreferrer">
                  <span class="sr-only">LinkedIn</span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-6 w-6" aria-hidden="true"><path d="M20.5 2h-17A1.5 1.5 0 002 3.5v17A1.5 1.5 0 003.5 22h17a1.5 1.5 0 001.5-1.5v-17A1.5 1.5 0 0020.5 2zM8 19H5v-9h3zM6.5 8.25A1.75 1.75 0 118.3 6.5a1.78 1.78 0 01-1.8 1.75zM19 19h-3v-4.74c0-1.42-.6-1.93-1.38-1.93A1.74 1.74 0 0013 14.19a.66.66 0 000 .14V19h-3v-9h2.9v1.3a3.11 3.11 0 012.7-1.4c1.55 0 3.36.86 3.36 3.66z"></path></svg>
                </a>
              </li>
            </ul>
          </header>

          {/* Contenido principal */}
          <main className="pt-24 lg:w-1/2 lg:py-24">
            {/* Sección sobre mí */}
            <section id="about" class="mb-16 scroll-mt-16 md:mb-24 lg:mb-36 lg:scroll-mt-24" aria-label="About me">
              <div class="sticky top-0 z-20 -mx-6 mb-4 w-screen bg-slate-900/75 px-6 py-5 backdrop-blur md:-mx-12 md:px-12 lg:sr-only lg:relative lg:top-auto lg:mx-auto lg:w-full lg:px-0 lg:py-0 lg:opacity-0">
                <h2 class="text-sm font-bold uppercase tracking-widest text-slate-200 lg:sr-only">About</h2>
              </div>
              <div>
                <p class="mb-4">
                  I strive to deliver high-quality solutions and enhance development efficiency
                  I always aim for the highest possible standards, combining my excellent communication skills and my ability to effectively engage in any environment and culture.
                  I am seeking challenging opportunities where I can contribute to a company's success with my technical skills and results-oriented mindset.
                </p>
              </div>
            </section>

            {/* Sección de Experiencia */}
            <section id="experience" class="mb-16 scroll-mt-16 md:mb-24 lg:mb-36 lg:scroll-mt-24" aria-label="Work experience">
              <div class="sticky top-0 z-20 -mx-6 mb-4 w-screen bg-slate-900/75 px-6 py-5 backdrop-blur md:-mx-12 md:px-12 lg:sr-only lg:relative lg:top-auto lg:mx-auto lg:w-full lg:px-0 lg:py-0 lg:opacity-0">
                <h2 class="text-sm font-bold uppercase tracking-widest text-slate-200 lg:sr-only">Experience</h2>
              </div>
              <div>
                <ol class="group/list relative border-s border-gray-200 dark:border-gray-700">
                  <li class="mb-12 ms-4">
                    <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                    <div class="group relative grid pb-1 transition-all sm:grid-cols-8 sm:gap-8 md:gap-4 lg:hover:!opacity-100 lg:group-hover/list:opacity-50">
                      <div class="absolute -inset-x-4 -inset-y-4 z-0 hidden rounded-md transition motion-reduce:transition-none lg:-inset-x-6 lg:block lg:group-hover:bg-slate-800/50 lg:group-hover:shadow-[inset_0_1px_0_0_rgba(148,163,184,0.1)] lg:group-hover:drop-shadow-lg"></div>
                      <header class="z-10 mb-2 mt-1 text-xs font-semibold uppercase tracking-wide text-slate-500 sm:col-span-2" aria-label="2018 to Present">Dec 2023 — Current</header>
                      <div class="z-10 sm:col-span-6">
                        <h3 class="font-medium leading-snug text-slate-200">

                          <div>
                            <div class="text-white-500" aria-hidden="true">Fullstack Developer</div>
                          </div>
                          <div>
                            <div class="text-slate-500" aria-hidden="true">Frelancer</div>
                          </div>
                        </h3>
                        <p class="mt-2 text-sm leading-normal">
                          I have worked as a freelance developer, delivering high-quality web development solutions and collaborating
                          with diverse clients to meet their unique project needs. My experience spans various technologies and industries,
                          enabling me to provide tailored, efficient, and innovative digital solutions.
                        </p>
                        {/* Tecnologías usadas */}
                        <ul class="mt-2 flex flex-wrap" aria-label="Technologies used">
                          <li class="mr-1.5 mt-2">
                            <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-teal-300 ">PHP</div>
                          </li>
                          <li class="mr-1.5 mt-2">
                            <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-teal-300 ">Laravel</div>
                          </li>
                          <li class="mr-1.5 mt-2">
                            <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-teal-300 ">Angular</div>
                          </li>
                          <li class="mr-1.5 mt-2">
                            <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-teal-300 ">JavaScript</div>
                          </li>
                          <li class="mr-1.5 mt-2">
                            <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-teal-300 ">PostgreSQL</div>
                          </li>
                          <li class="mr-1.5 mt-2">
                            <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-teal-300 ">GIT</div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li class="mb-12 ms-4">
                    <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                    <div class="group relative grid pb-1 transition-all sm:grid-cols-8 sm:gap-8 md:gap-4 lg:hover:!opacity-100 lg:group-hover/list:opacity-50">
                      <div class="absolute -inset-x-4 -inset-y-4 z-0 hidden rounded-md transition motion-reduce:transition-none lg:-inset-x-6 lg:block lg:group-hover:bg-slate-800/50 lg:group-hover:shadow-[inset_0_1px_0_0_rgba(148,163,184,0.1)] lg:group-hover:drop-shadow-lg"></div>
                      <header class="z-10 mb-2 mt-1 text-xs font-semibold uppercase tracking-wide text-slate-500 sm:col-span-2" aria-label="2018 to Present">2021 — Oct 2023</header>
                      <div class="z-10 sm:col-span-6">
                        <h3 class="font-medium leading-snug text-slate-200">

                          <div>
                            <div class="text-white-500" aria-hidden="true">Backend Developer</div>
                          </div>
                          <div>
                            <div class="text-slate-500" aria-hidden="true">Userlytics Corporation</div>
                          </div>
                        </h3>
                        <p class="mt-2 text-sm leading-normal">
                          Developed and implemented innovative features to enhance platform functionality.
                          Directly engaged with clients and users, providing assistance and resolving inquiries.
                          Led technical support efforts for Userlytics Corporation's web platform.
                          Identified unaddressed issues and conducted rigorous testing to ensure top-notch quality.
                          My tenure at Userlytics Corporation provided a dynamic and collaborative environment, allowing me to apply my backend development skills and efficient problem-solving abilities.
                        </p>
                        {/* Tecnologías usadas */}
                        <ul class="mt-2 flex flex-wrap" aria-label="Technologies used">
                          <li class="mr-1.5 mt-2">
                            <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-teal-300 ">PHP</div>
                          </li>
                          <li class="mr-1.5 mt-2">
                            <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-teal-300 ">Laravel</div>
                          </li>
                          <li class="mr-1.5 mt-2">
                            <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-teal-300 ">YII</div>
                          </li>
                          <li class="mr-1.5 mt-2">
                            <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-teal-300 ">Angular</div>
                          </li>
                          <li class="mr-1.5 mt-2">
                            <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-teal-300 ">JavaScript</div>
                          </li>
                          <li class="mr-1.5 mt-2">
                            <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-teal-300 ">PostgreSQL</div>
                          </li>
                          <li class="mr-1.5 mt-2">
                            <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-teal-300 ">GIT</div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li class="mb-12 ms-4">
                    <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                    <div class="group relative grid pb-1 transition-all sm:grid-cols-8 sm:gap-8 md:gap-4 lg:hover:!opacity-100 lg:group-hover/list:opacity-50">
                      <div class="absolute -inset-x-4 -inset-y-4 z-0 hidden rounded-md transition motion-reduce:transition-none lg:-inset-x-6 lg:block lg:group-hover:bg-slate-800/50 lg:group-hover:shadow-[inset_0_1px_0_0_rgba(148,163,184,0.1)] lg:group-hover:drop-shadow-lg"></div>
                      <header class="z-10 mb-2 mt-1 text-xs font-semibold uppercase tracking-wide text-slate-500 sm:col-span-2" aria-label="2018 to Present">2018 — Jul 2019</header>
                      <div class="z-10 sm:col-span-6">
                        <h3 class="font-medium leading-snug text-slate-200">

                          <div>
                            <div class="text-white-500" aria-hidden="true">Web Developer</div>
                          </div>
                          <div>
                            <div class="text-slate-500" aria-hidden="true">Yunbit</div>
                          </div>
                        </h3>
                        <p class="mt-2 text-sm leading-normal">
                          Ability to establish direct relationships with clients, understanding their specific needs and providing effective and personalized solutions.
                          Excellence in proactively identifying potential project flaws, conducting exhaustive tests to ensure quality and optimal performance.
                          Experience in providing comprehensive technical support and maintenance for web projects, ensuring their optimal performance.
                          Close and effective collaboration with development teams, ensuring successful and timely project delivery through a cooperative and coordinated approach.
                        </p>
                        {/* Tecnologías usadas */}
                        <ul class="mt-2 flex flex-wrap" aria-label="Technologies used">
                          <li class="mr-1.5 mt-2">
                            <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-teal-300 ">PHP</div>
                          </li>
                          <li class="mr-1.5 mt-2">
                            <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-teal-300 ">HTML5</div>
                          </li>
                          <li class="mr-1.5 mt-2">
                            <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-teal-300 ">JSON</div>
                          </li>
                          <li class="mr-1.5 mt-2">
                            <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-teal-300 ">Laravel 5</div>
                          </li>
                          <li class="mr-1.5 mt-2">
                            <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-teal-300 ">JavaScript</div>
                          </li>
                          <li class="mr-1.5 mt-2">
                            <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-teal-300 ">MYSQL</div>
                          </li>
                          <li class="mr-1.5 mt-2">
                            <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-teal-300 ">GIT</div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li class="mb-12 ms-4">
                    <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                    <div class="group relative grid pb-1 transition-all sm:grid-cols-8 sm:gap-8 md:gap-4 lg:hover:!opacity-100 lg:group-hover/list:opacity-50">
                      <div class="absolute -inset-x-4 -inset-y-4 z-0 hidden rounded-md transition motion-reduce:transition-none lg:-inset-x-6 lg:block lg:group-hover:bg-slate-800/50 lg:group-hover:shadow-[inset_0_1px_0_0_rgba(148,163,184,0.1)] lg:group-hover:drop-shadow-lg"></div>
                      <header class="z-10 mb-2 mt-1 text-xs font-semibold uppercase tracking-wide text-slate-500 sm:col-span-2" aria-label="2018 to Present">2017 — Feb 2018</header>
                      <div class="z-10 sm:col-span-6">
                        <h3 class="font-medium leading-snug text-slate-200">

                          <div>
                            <div class="text-white-500" aria-hidden="true">Frontend Developer</div>
                          </div>
                          <div>
                            <div class="text-slate-500" aria-hidden="true">Amaris Consulting</div>
                          </div>
                        </h3>
                        <p class="mt-2 text-sm leading-normal">
                          Extensive experience in structuring and designing dynamic web projects.
                          Implementing new functionalities and animations using JavaScript to enhance interactivity.
                          Ability to adapt projects to various devices, ensuring responsiveness across platforms.
                          Proactive and continuous maintenance of web projects to ensure optimal performance and functionality.
                        </p>
                        {/* Tecnologías usadas */}
                        <ul class="mt-2 flex flex-wrap" aria-label="Technologies used">
                          <li class="mr-1.5 mt-2">
                            <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-teal-300 ">HTML5</div>
                          </li>
                          <li class="mr-1.5 mt-2">
                            <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-teal-300 ">CSS3</div>
                          </li>
                          <li class="mr-1.5 mt-2">
                            <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-teal-300 ">JQUERY</div>
                          </li>
                          <li class="mr-1.5 mt-2">
                            <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-teal-300 ">JavaScript</div>
                          </li>
                          <li class="mr-1.5 mt-2">
                            <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-teal-300 ">Boostrap</div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ol>
              </div>
            </section>

            {/* Sección de proyectos */}
            <section id="projects" class="mb-16 scroll-mt-16 md:mb-24 lg:mb-36 lg:scroll-mt-24" aria-label="Selected projects">
              <div class="sticky top-0 z-20 -mx-6 mb-4 w-screen bg-slate-900/75 px-6 py-5 backdrop-blur md:-mx-12 md:px-12 lg:sr-only lg:relative lg:top-auto lg:mx-auto lg:w-full lg:px-0 lg:py-0 lg:opacity-0">
                <h2 class="text-sm font-bold uppercase tracking-widest text-slate-200 lg:sr-only">Projects</h2>
              </div>
              <div>
                <ul class="group/list">
                  <li class="mb-12">
                    <div class="group relative grid gap-4 pb-1 transition-all sm:grid-cols-8 sm:gap-8 md:gap-4 lg:hover:!opacity-100 lg:group-hover/list:opacity-50">
                      <div class="absolute -inset-x-4 -inset-y-4 z-0 hidden rounded-md transition motion-reduce:transition-none lg:-inset-x-6 lg:block lg:group-hover:bg-slate-800/50 lg:group-hover:shadow-[inset_0_1px_0_0_rgba(148,163,184,0.1)] lg:group-hover:drop-shadow-lg"></div>
                      <div class="z-10 sm:order-2 sm:col-span-6">
                        <h3>
                          <a class="inline-flex items-baseline font-medium leading-tight text-slate-200 hover:text-teal-300 focus-visible:text-teal-300  group/link text-base"
                            href="https://israelpereira.netlify.app" target="_blank" rel="noreferrer" aria-label="">
                            <span class="absolute -inset-x-4 -inset-y-2.5 hidden rounded md:-inset-x-6 md:-inset-y-4 lg:block"></span>
                            <span>Card Website</span>
                          </a>
                        </h3>
                        <p class="mt-2 text-sm leading-normal">This design offers a distinct layout based on cards, allowing you to highlight specific information for a portfolio, this was built with HTML CSS and JavaScript.</p>
                      </div>
                      <img alt="" loading="lazy" width="200" height="48" decoding="async" data-nimg="1" class="rounded border-2 border-slate-200/10 transition group-hover:border-slate-200/30 sm:order-1 sm:col-span-2 sm:translate-y-1"
                        srcset="/img/proyecto1.png" src="/img/proyecto1.png" />
                    </div>
                  </li>
                  <li class="mb-12">
                    <div class="group relative grid gap-4 pb-1 transition-all sm:grid-cols-8 sm:gap-8 md:gap-4 lg:hover:!opacity-100 lg:group-hover/list:opacity-50">
                      <div class="absolute -inset-x-4 -inset-y-4 z-0 hidden rounded-md transition motion-reduce:transition-none lg:-inset-x-6 lg:block lg:group-hover:bg-slate-800/50 lg:group-hover:shadow-[inset_0_1px_0_0_rgba(148,163,184,0.1)] lg:group-hover:drop-shadow-lg"></div>
                      <div class="z-10 sm:order-2 sm:col-span-6">
                        <h3>
                          <a class="inline-flex items-baseline font-medium leading-tight text-slate-200 hover:text-teal-300 focus-visible:text-teal-300  group/link text-base"
                            href="https://socialnet.israelpereira.net" target="_blank" rel="noreferrer" aria-label="">
                            <span class="absolute -inset-x-4 -inset-y-2.5 hidden rounded md:-inset-x-6 md:-inset-y-4 lg:block"></span>
                            <span>SocialNet</span>
                          </a>
                        </h3>
                        <p class="mt-2 text-sm leading-normal">This is a red social was making with Laravel and PHP.</p>
                      </div>
                      <img alt="" loading="lazy" width="200" height="48" decoding="async" data-nimg="1" class="rounded border-2 border-slate-200/10 transition group-hover:border-slate-200/30 sm:order-1 sm:col-span-2 sm:translate-y-1"
                        srcset="/img/proyecto2.png" src="/img/proyecto2.png" />
                    </div>
                  </li>
                  <li class="mb-12">
                    <div class="group relative grid gap-4 pb-1 transition-all sm:grid-cols-8 sm:gap-8 md:gap-4 lg:hover:!opacity-100 lg:group-hover/list:opacity-50">
                      <div class="absolute -inset-x-4 -inset-y-4 z-0 hidden rounded-md transition motion-reduce:transition-none lg:-inset-x-6 lg:block lg:group-hover:bg-slate-800/50 lg:group-hover:shadow-[inset_0_1px_0_0_rgba(148,163,184,0.1)] lg:group-hover:drop-shadow-lg"></div>
                      <div class="z-10 sm:order-2 sm:col-span-6">
                        <h3>
                          <a class="inline-flex items-baseline font-medium leading-tight text-slate-200 hover:text-teal-300 focus-visible:text-teal-300  group/link text-base"
                            href="https://github.com/franper" target="_blank" rel="noreferrer" aria-label="">
                            <span class="absolute -inset-x-4 -inset-y-2.5 hidden rounded md:-inset-x-6 md:-inset-y-4 lg:block"></span>
                            <span>More Projects in progress</span>
                          </a>
                        </h3>
                        <p class="mt-2 text-sm leading-normal">There are some projects that are comming soon but you can check it in the repositories github.</p>
                      </div>
                      <img alt="" loading="lazy" width="200" height="48" decoding="async" data-nimg="1" class="rounded border-2 border-slate-200/10 transition group-hover:border-slate-200/30 sm:order-1 sm:col-span-2 sm:translate-y-1" srcset="https://www.fullfreelancer.com/assets/images/reparacion2.webp" src="https://www.fullfreelancer.com/assets/images/reparacion2.webp" />
                    </div>
                  </li>
                  {/* para añadir mas proyectos copia el li de arriba y adaptalo */}
                </ul>
              </div>
            </section>

            <footer className="bg-gray-900 text-white py-4">
              <div className="container mx-auto px-4 text-center">
                {/* Información adicional o enlaces */}
                <p>Contact: info.israelpereira@gmail.com</p>
                <p>Israel Pereira &copy; {new Date().getFullYear()} this web has been built with ReactJS</p>
              </div>
            </footer>
          </main>

        </div >
      </div >
    </div >
  );
};

export default App;

